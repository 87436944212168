@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/dark.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@font-face {
    font-family: 'Shree Devanagari 714'; //This is what we are going to call
    src: url('../src/assets/fonts/ShreeDevanagari714.ttf');
    font-weight: normal;
    font-style: normal;
}

// * {
//     font-family: 'Shree Devanagari 714' !important;
// }

*:not(i) {
    font-family: 'Shree Devanagari 714' !important;
}